
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');


.robotomono {
    font-family: 'Roboto Mono', monospace;

  }

  @import url('https://fonts.googleapis.com/css2?family=Jura:wght@600&display=swap');


.jura{
    font-family: 'Jura', sans-serif;
    
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.montserrat{
    font-family: 'Montserrat', sans-serif;
}

.link {
  text-decoration:none;
}