@media screen and (max-width: 640px) {
  /* reglas CSS */
  .hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px;
    border-color: transparent;
  }
  .hovereffect div.info,
  .hovereffect h4,
  .hovereffect h6,
  .hovereffect h5 {
    text-align: center;
    color: black;
    font-size: 2vw;
    padding: 5%;
  }
}
@media screen and (max-width: 1280px) and (min-width: 698px) {
  /* reglas CSS */

  .hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    padding-top: 6px;
    padding-bottom: 6px;
    border-color: transparent;
  }
  .hovereffect div.info,
  .hovereffect h4,
  .hovereffect h6,
  .hovereffect h5 {
    text-align: center;
    color: black;
    font-size: 1.5vw;
    padding: 5%;
  }
}

@media screen and (min-width: 1024px) {
  /* reglas CSS */

  .hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    padding-top: 6px;
    padding-bottom: 6px;
    border-color: transparent;
  }

  .hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    border-color: transparent;
  }

  .hovereffect img {
    display: block;
    position: relative;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    border-color: transparent;
  }

  .hovereffect:hover img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feGaussianBlur stdDeviation="3" /></filter></svg>#filter');
    filter: grayscale(0) blur(3px);
    -webkit-filter: grayscale(0) blur(3px);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1);
    border-color: transparent;
  }

  .hovereffect div.info,
  .hovereffect h4,
  .hovereffect h6,
  .hovereffect h5 {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    opacity: 0;
    filter: alpha(opacity=0);
    color: black;
    text-transform: ;
    border-color: transparent;
    padding-top: 10%;
  }

  .hovereffect:hover div.info,
  .hovereffect:hover h4,
  .hovereffect:hover h6,
  .hovereffect:hover h5 {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border-color: transparent;
  }
}
